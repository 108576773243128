import React,{useEffect,useState} from 'react'
import axios from 'axios'
import { fetch_data } from '../Services/api';
import Header from '../Components/Header';
import classes from './Home.module.css'
import Slider from '../Components/Slider';
import Topic from '../Components/Topic';
import TopSlider from '../Components/TopSlider';
import Videos from '../Components/Videos';
import Lottie from "lottie-react";
import loader from '../Assets/loader.json'



const Home = () => {

    const [videos,setVideos] = useState([]);
    const [categories,setCategories] = useState([])
    const [loading,setLoading] = useState(false);

    const fetchData=async()=>{
        setLoading(true);
        const res = await axios.get(fetch_data);
        console.log(res.data)

        setVideos(res?.data?.videos)
        setCategories(res.data.categories)
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])


  return (
    <div className={classes.container}>
    {
        !loading ?
        <>
    <Header/>
    <div className={classes.slider}>
    <Slider videos={videos}/>
    
    </div>
    <Topic topic='Top'/>

    <div className={classes.slider}>
    <TopSlider videos={videos}/>
    
    </div>

    {
        categories.map((item)=>(
            <>
                <Topic topic={item.name}/>
                <div>
                <Videos videos={videos?.filter((video)=>video?.categoryId == item?.sub_cat_id)}/>
                </div>
            </>
        ))
    }
    
    </>:
    <>
    <Lottie animationData={loader} className={classes.animation}/>
    </>
    }
     
    </div>
  )
}

export default Home
