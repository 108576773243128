import React,{useEffect,useState} from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination,Autoplay} from 'swiper/modules';
import classes from './Slider.module.css'
import {Link} from 'react-router-dom'

const Slider = ({videos}) => {

  const [randomVideos, setRandomVideos] = useState([]);


  const getRandomItems = (array, count) => {
    const shuffled = [...array].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };


  useEffect(() => {
    if (videos && videos.length > 0) {
      const randomSelection = getRandomItems(videos, 5); // Change 5 to the number of videos you want
      setRandomVideos(randomSelection);
    }
  }, [videos]);


  return (
    <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}


        breakpoints={{
        // Breakpoints for responsive design
        320: {
          slidesPerView: 1, // 1 slide for small screens (e.g., mobile)
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 2, // 2 slides for tablets
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3, // 3 slides for desktops
          spaceBetween: 30,
        },
      }}
      autoplay={{
        delay: 3000, // Slide changes every 3 seconds
        disableOnInteraction: false, // Autoplay continues even after user interaction
      }}
        modules={[Autoplay]}
        className="mySwiper"
      >
      {randomVideos.map((item,idx)=>(
        <>
        <SwiperSlide>
        <Link to={`/kids-video/${item.id}`}>
            <img src={item?.imageUrl || `https://sierra-leone-kids-content.s3.ap-south-1.amazonaws.com/Images/Rhymes/Ladybug+Song.png`} alt="https://sierra-leone-kids-content.s3.ap-south-1.amazonaws.com/Images/Rhymes/Ladybug+Song.png" className={classes.img} />
            <div className={classes.title}>
            <p className={classes.name}>  {item?.name}</p>
            </div>
            </Link>
        </SwiperSlide>
        </>
      ))}
        
       
      </Swiper>
  )
}

export default Slider
