import React from 'react'
import classes from './Videos.module.css'
import { FaPlayCircle } from "react-icons/fa";
import {Link} from 'react-router-dom'


const Videos = ({videos}) => {
  return (
    <div className={classes.container}>
       <div className={classes.videos}>
                {
                    videos?.map((VideoItem)=>(
                        <Link to={`/kids-video/${VideoItem?.id}`} className={classes.img}>
                            <img src={VideoItem.imageUrl || `https://sierra-leone-kids-content.s3.ap-south-1.amazonaws.com/Images/Rhymes/Ladybug+Song.png`} alt="" />
                            <div className={classes.name}>  <p className={classes.text}><FaPlayCircle className={classes.icon}/> {VideoItem.name}</p></div>
                        </Link>
                    ))
                }
                
                </div>
    </div>
  )
}

export default Videos
