import React from 'react'
import classes from './VideoFooter.module.css'
import { IoMdShare } from "react-icons/io";
import { FaRegEye } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";


const VideoFooter = ({videoName}) => {
  console.log(videoName)
  return (
    <div className={classes.container}>
    <div className={classes.sub_container}>
    <p className={classes.title}>{videoName}</p>
    <div className={classes.options}>
    <div className={classes.share}>
    <IoMdShare/>
    Share
    </div>
    <div className={classes.like}>
    <AiFillLike/>
    Likes
    </div>
    <div className={classes.views}>
    <FaRegEye/>
    Views
    </div>
    </div>
    </div>
    </div>
  )
}

export default VideoFooter
