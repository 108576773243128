import React from 'react'
import classes from './Topic.module.css'
import { SiFireship } from "react-icons/si";


const Topic = ({topic}) => {
  return (
    <div className={classes.topic_container}>
      <div className={classes.text}>
      {topic == 'Top' && <SiFireship className={classes.icon}/>}
      {topic}
      </div>
    </div>
  )
}

export default Topic
