import React from 'react'
import logo from '../Assets/logo.webp'
import { IoIosMenu } from "react-icons/io";
import classes from './Header.module.css'


const Header = () => {
  return (
    <div className={classes.container}>
      <div className={classes.logo}>
      <img src={logo} alt="" className={classes.img} />
      </div>
      <div className={classes.menuBar}>
      <IoIosMenu/>
      </div>

    </div>
  )
}

export default Header
