import React,{useEffect,useState} from 'react'
import ReactPlayer from 'react-player'
import VideoFooter from '../Components/VideoFooter'
import classes from './Video.module.css'
import Topic from '../Components/Topic'
import Videos from '../Components/Videos'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import { fetch_data } from '../Services/api';
import Lottie from "lottie-react";
import loader from '../Assets/loader.json'


const Video = () => {

  const [video,setVideo] = useState();
  const [videos,setVideos] = useState([]);
  const [similarVideos,setSimilarVideos] = useState([]);
  const [loading,setLoading] = useState(false);

  const {id} = useParams();

  const fetchData=async()=>{
    setLoading(true)
    try{
      const res = await axios.get(fetch_data);
    console.log(res.data.videos)

    setVideos(res?.data?.videos)
    const resp = res.data.videos?.filter((item)=>item.id == id)
    console.log(resp,'res========')
    setVideo(resp[0])
    const similar = res.data.videos?.filter((item)=>item?.categoryId == resp[0]?.categoryId)
    setSimilarVideos(similar)
    console.log(similarVideos,'similar')
    }
    catch(err){
      console.log(err)
    }
    setLoading(false)
    // setCategories(res.data.categories)
}


console.log(video?.name,'video  ')

  // console.log(id)
  useEffect(() => {
     fetchData();
   
  }, [id])
  

  return (
   <div className={classes.main}>
    {
      !loading ?
      <div className={classes.container}>
    <div className={classes.React_player}>
    <ReactPlayer url={video?.videoUrl}
     playing={true}
          controls={true}
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          progressInterval={200}
          // onStart={() => videoPlaying(videoItem.id)}
          playsinline={true}
          // onEnded={() => router.push(`/${serviceName}/${nextVideo.id}`)}
          // light={isAndroid ? false : videoItem.imageUrl ||  "https://sierra-leone-kids-content.s3.ap-south-1.amazonaws.com/Images/Rhymes/Ladybug+Song.png"}
         
     className={classes.player} />
     </div>
    <VideoFooter videoName={video?.name}/>
    <Topic topic='Similar Videos'/>

    <Videos videos={similarVideos}/>
    
    </div>
    :
    <>
    <Lottie animationData={loader} className={classes.animation}/> 
    </>
    }
   </div>
  )
}

export default Video
