import logo from './logo.svg';
import './App.css';
import React,{useEffect} from 'react'
import Home from './Pages/Home';
import {Routes,Route} from 'react-router-dom'
import Video from './Pages/Video';
import {useNavigate} from 'react-router-dom'

function App() {

  const GoToPage=()=>{
    const navigate = useNavigate()
    useEffect(() => {
      navigate('/kids-video')
    }, [])

    return(<></>);
  }


  return (
   <>
   <Routes>
   <Route path='/' element={<GoToPage/>}/>
    <Route path='/kids-video' element={<Home/>}/>
    <Route path='/kids-video/:id' element={<Video/>}/>
   </Routes>
   </>
  );
}

export default App;
