import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination,Autoplay} from 'swiper/modules';
import classes from './TopSlider.module.css'
import {Link} from 'react-router-dom'
import { MdChildCare } from "react-icons/md";


const TopSlider = ({videos}) => {
  return (
    // <div>
       <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
        clickable: true,
        dynamicBullets: true, // Show only the number of bullets needed
      }}


        breakpoints={{
        // Breakpoints for responsive design
        320: {
          slidesPerView: 2, // 1 slide for small screens (e.g., mobile)
          spaceBetween: 10,
        },
        640: {
          slidesPerView: 4, // 2 slides for tablets
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 6, // 3 slides for desktops
          spaceBetween: 30,
        },
      }}
      autoplay={{
        delay: 3000, // Slide changes every 3 seconds
        disableOnInteraction: false, // Autoplay continues even after user interaction
      }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
      >
      {videos.slice(0,10).map((item,idx)=>(
        <>
        <SwiperSlide className={classes.slide}>
        <Link to={`/kids-video/${item.id}`}>
            <img src={item?.imageUrl || `https://sierra-leone-kids-content.s3.ap-south-1.amazonaws.com/Images/Rhymes/Ladybug+Song.png`} alt="" className={classes.img} />
            <div className={classes.title}>
            <p className={classes.name}> <MdChildCare/> {item?.name}</p>
            </div>
            </Link>
        </SwiperSlide>
        </>
      ))}
        
       
      </Swiper>
    // </div>
  )
}

export default TopSlider
